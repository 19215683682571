import React, { ReactChild, useState } from 'react';

type Option = {
    panelLabel: ReactChild;
    panelContent: ReactChild;
}

type Props = {
    children: Option[];
    contextStyle?: string;
}

export default function TabPanel({ children, contextStyle } : Props) {
    const [selected, setSelected] = useState(0);

    const selectedStyle = (key: number) => {
        if(selected === key)
            return ' selected';
        return '';
    }

    return (
    <>
        <div className={['tab-menu', contextStyle || ''].join(' ')}>
            {children.map((o, i) => (<div className={'tab-option' + selectedStyle(i)} key={i} onClick={() => setSelected(i)}>{o.panelLabel}</div>))}
        </div>
        <div className={['tab-content', contextStyle || ''].join(' ')}>
            {selected < children.length && children[selected].panelContent}
        </div>
    </>);
}
